<template>
  <div>
    <h1 class="text-2xl font-semibold">Tài khoản của tôi</h1>
    <div class="grid lg:grid-cols-3 mt-12 gap-8">
      <div>
        <h3 class="text-xl mb-2">Cho web gửi notification</h3>
      </div>
      <div class="col-span-2">
        <div class="pb-4">
          <label class="switch flex items-center mb-4">
            <input type="checkbox" v-model="notificationOn">
            <span class="switch-button"></span>
          </label>
        </div>
        <p>Bạn có thể thay đổi quyền bằng cách gõ <span class="font-semibold">chrome://settings/content/notifications</span> trên trình duyệt</p>
      </div>
      <div>
        <h3 class="text-xl mb-2">Thông tin tài khoản</h3>
      </div>
      <form v-on:submit.prevent="updateMe" class="bg-white rounded-md lg:shadow-md shadow col-span-2">
        <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
          <div class="col-span-2">
            <label for="fullname">Họ và tên</label>
            <input type="text" id="fullname" v-model="account.fullname" placeholder="" class="shadow-none with-border">
          </div>
          <div class="col-span-2">
            <label>Ngày sinh</label>
            <birthday v-model:dob="account.dob" v-model:mob="account.mob" v-model:yob="account.yob"/>
          </div>
          <div class="col-span-2">
            <label for="address">Địa chỉ</label>
            <input type="text" v-model="account.address" placeholder="" id="address" class="shadow-none with-border">
          </div>
          <div>
            <label for="phone">Số điện thoại</label>
            <input type="text" v-model="account.phone" placeholder="" id="phone" class="shadow-none with-border">
          </div>
          <div>
            <label for="phone">Giới tính</label>
            <div class="flex items-center space-x-4">
              <label class="flex items-center m-0"><input type="radio" v-model="account.gender" value="M" class="mr-1"/>Nam</label>
              <label class="flex items-center m-0"><input type="radio" v-model="account.gender" value="F" class="mr-1"/>Nữ</label>
            </div>
          </div>
        </div>

        <div class="bg-gray-10 p-6 pt-0 flex justify-end space-x-3">
          <button type="submit" class="button hover:bg-green-700 bg-green-600">Lưu lại</button>
        </div>
      </form>

      <div>
        <h3 class="text-xl mb-2">Ảnh đại diện</h3>
      </div>
      <div class="bg-white rounded-md lg:shadow-md shadow col-span-2">
        <div class="p-5 grid grid-cols-2 gap-4">
          <DropZone type="image" image-class="" :imageProp="account.avatar ? account.avatar.url : null" v-model:objectIdProp="avatarId"/>
          <div class="flex justify-end" v-if="avatarId !== account.image_id">
            <button type="button" v-on:click="updateAvatar" class="button bg-blue-700">Lưu lại</button>
          </div>
        </div>
      </div>

      <div>
        <h3 class="text-xl mb-2">Ảnh bìa</h3>
      </div>
      
      <div class="bg-white rounded-md lg:shadow-md shadow col-span-2">
        <div class="p-5">
          <p>Kích thước tiêu chuẩn: 1000x250</p>
          <DropZone type="image" image-class="" :imageProp="account.background ? account.background.url : null" v-model:objectIdProp="backgroundId"/>
          <div class="flex justify-end" v-if="backgroundId !== account.background_id">
            <button type="button" v-on:click="updateBackground" class="button bg-blue-700">Lưu lại</button>
          </div>
        </div>
      </div>

      <div>
        <h3 class="text-xl mb-2">Đổi mật khẩu</h3>
      </div>
      <div class="bg-white rounded-md lg:shadow-md shadow col-span-2">
        <div class="p-5">
          <form v-on:submit.prevent="changePassword">
            <div class="">
              <label for="password">Mật khẩu mới</label>
              <input required autocomplete="off" type="text" id="password" v-model="password" placeholder="" class="shadow-none with-border">
            </div>
            <div class="flex justify-end mt-3">
              <button type="submit" class="button hover:bg-green-700 bg-green-600">Lưu lại</button>
            </div>
          </form>
        </div>
      </div>

      <div>
        <h3 class="text-xl mb-2">Đổi username</h3>
      </div>
      <div class="bg-white rounded-md lg:shadow-md shadow col-span-2">
        <div class="p-5">
          <form v-on:submit.prevent="changeUsername">
            <div class="">
              <label for="new_username">Username mới</label>
              <input required autocomplete="off" type="text" id="new_username" v-model="new_username" placeholder="" class="shadow-none with-border">
            </div>
            <div class="mt-3">
              <label for="reason">Lý do thay đổi</label>
              <input required autocomplete="off" type="text" id="reason" v-model="reason" placeholder="" class="shadow-none with-border">
            </div>
            <div class="flex justify-end mt-3">
              <button type="submit" class="button hover:bg-green-700 bg-green-600">Yêu cầu</button>
            </div>
          </form>

          <div class="mt-4" v-if="account.changeUsernameRequests && account.changeUsernameRequests.data && account.changeUsernameRequests.data.length">
            <div class="content">
              <table>
                <thead>
                  <tr>
                    <td>Username cũ</td>
                    <td>Username mới</td>
                    <td>Lý do</td>
                    <td>Trạng thái</td>
                    <td>Thời gian</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="request in account.changeUsernameRequests.data" :key="'changeUsernameRequests' + request.id">
                    <td>{{ request.old_username }}</td>
                    <td><strong>{{ request.new_username }}</strong></td>
                    <td>{{ request.reason }}</td>
                    <td class="whitespace-nowrap">
                      <span class="px-2 py-1 text-xs rounded-full text-white" :class="{'bg-gray-500': request.status == 'pending', 'bg-green-500': request.status == 'approved', 'bg-red-500': request.status == 'rejected' || request.status == 'canceled'}">{{ getStatusText(request.status) }}</span>
                      <span v-if="request.status === 'rejected' && request.reject_reason" class="ml-1"><i class="far fa-question-circle"
                                                                            :uk-tooltip="'title: ' + request.reject_reason"></i></span>
                    </td>
                    <td class="whitespace-nowrap">
                      {{ timeago(request.created_at) }}
                      <button v-if="request.status == 'pending'" class="px-2 py-1 text-xs bg-gray-500 text-xs text-white" v-on:click="cancelChangeUsername(request.id)">Hủy</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div class="mt-4" v-if="account.changeUsernameRequests.paginatorInfo.lastPage > 1">
              <v-pagination
                  class="justify-content-center"
                  v-model="account.changeUsernameRequests.paginatorInfo.currentPage"
                  :pages="account.changeUsernameRequests.paginatorInfo.lastPage"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="loadMe"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Birthday from "../Birthday";
import ApiService from "../../core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {
  timeago
} from "../../core/services/utils.service";
import DropZone from "../DropZone";
import { getUser } from "../../core/services/jwt.service";

export default {
  name: "MyAccount",
  components: {Birthday, DropZone, VPagination},
  data() {
    return {
      notificationOn: false,
      account: {
        fullname: "",
        email: "",
        dob: "",
        mob: "",
        yob: "",
        address: "",
        phone: "",
        gender: "",
        image_id: null,
        background_id: null,
      },
      avatarId: null,
      backgroundId: null,
      password: "",
      new_username: "",
      reason: "",
    }
  },
  methods: {
    loadMe() {
      let query = `query {
        me {
          id
          fullname
          address
          phone
          dob
          mob
          yob
          gender
          image_id
          background_id
          avatar {
            url
          }
          background {
            url
          }
          changeUsernameRequests(first: 3, orderBy: [{column: "id", order: DESC}]) {
            data {
              id
              old_username
              new_username
              reason
              reject_reason
              status
              created_at
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.me) {
              this.account = data.data.me;
              this.avatarId = data.data.me.image_id;
              this.backgroundId = data.data.me.background_id;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updateMe() {
      let query = `mutation($fullname: String, $phone: String, $address: String, $dob: String, $mob: String, $yob: String, $gender: String) {
        updateMe(fullname: $fullname, phone: $phone, address: $address, dob: $dob, mob: $mob, yob:$yob, gender: $gender) {
          id
        }
      }`;

      ApiService.graphql(query, this.account)
        .then(({data}) => {
            if (data.data && data.data.updateMe) {
              this.$toast.success("Cập nhật thành công");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    updateAvatar() {
      let query = `mutation($image_id: ID) {
        updateMe(image_id: $image_id) {
          id
        }
      }`;

      ApiService.graphql(query, {image_id: this.avatarId})
          .then(({data}) => {
            if (data.data && data.data.updateMe) {
              this.$toast.success("Cập nhật thành công");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    updateBackground() {
      let query = `mutation($background_id: ID) {
        updateMe(background_id: $background_id) {
          id
        }
      }`;

      ApiService.graphql(query, {background_id: this.backgroundId})
          .then(({data}) => {
            if (data.data && data.data.updateMe) {
              this.$toast.success("Cập nhật thành công");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    changePassword() {
      let query = `mutation($password: String!) {
        changePassword(password: $password)
      }`;

      ApiService.graphql(query, {password: this.password})
          .then(({data}) => {
            if (data.data && data.data.changePassword) {
              this.$toast.success("Cập nhật thành công");
              this.password = "";
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    changeUsername() {
      let query = `mutation($new_username: String!, $reason: String) {
        changeUsername(new_username: $new_username, reason: $reason) {
          id
        }
      }`;

      ApiService.graphql(query, {new_username: this.new_username, reason: this.reason})
          .then(({data}) => {
            if (data.data && data.data.changeUsername) {
              this.$toast.success("Yêu cầu thay đổi username thành công");
              this.new_username = "";
              this.reason = "";
              this.loadMe();
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    cancelChangeUsername(request_id) {
      if (confirm("Bạn có chắc chắn muốn hủy yêu cầu này?") == true) {
        let query = `mutation($request_id: ID!) {
          cancelChangeUsername(request_id: $request_id) {
            id
          }
        }`;

        ApiService.graphql(query, {request_id: request_id})
            .then(({data}) => {
              if (data.data && data.data.cancelChangeUsername) {
                this.$toast.success("Hủy thành công");
                this.loadMe();
              } else {
                this.$toast.error(data.errors[0].message);
              }
            })
            .catch((response) => {
              this.$toast.error(response.message);
            });
      }
    },
    requestPermission() {
      Notification.requestPermission().then((status) => {
        if (status === "granted") {
          this.notificationOn = true;
        }
      });
    },
    getStatusText(status) {
      switch (status) {
        case "pending":
          return "Đang chờ";
        case "approved":
          return "Đồng ý";
        case "rejected":
          return "Từ chối";
        case "canceled":
          return "Hủy";
      }
    },
    timeago(x) {
      return timeago(x);
    }
  },
  mounted() {
    this.loadMe();
    this.notificationOn = Notification.permission === "granted";
    let user = getUser();
    if (!user) {
      this.$router.push({name: "Home"});
    }
  },
  watch: {
    notificationOn: {
      handler() {
        this.requestPermission();
      }
    }
  }
}
</script>
